/** @format */

import React from "react";

const Rebase = () => {
    React.useEffect(() => {
        document.title = "Rebase | Relymer";
      }, []);
  return (
    <>
      <iframe
        src='https://relymer-dashboard.netlify.app'
        width='100%'
        title='Rebase'
        // scrolling='no'
        frameborder='0'></iframe>
    </>
  );
};

export default Rebase;
