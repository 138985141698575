/** @format */

import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
const Hedera = () => {
  return (
    <>
      <div className='hedera_sec'>
        <div className='container'>
          <div className='img2'>
            <img src='assets/images/h3.png' className='img-fluid img-3' alt='' />
            <div>
              {/* <img
                src='assets/images/h1.png'
                className='img-fluid img3'
                alt=''
              /> */}
            </div>
            <div className='text m-auto text-center'>
              <h3>
                <span>Relymer's Blockchain Technology</span>
                <br /> Stack Deployment
              </h3>
              <Tabs
                defaultActiveKey='Blockchain Stack'
                id='uncontrolled-tab-example'
                className='mb-3 hedera_tabs'>
                <Tab
                  eventKey='Blockchain Stack'
                  title={<span>Blockchain Stack</span>}>
                  <div className='d-flex justify-content-around flex-wrap gap-4 tab-res'>
                    <div className='img3'>
                      <img
                        src='assets/images/binance.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Binance</p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/eth.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Ethereum</p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/klaytn-logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>klaytn</p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/matic-logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Polygon </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/solana-logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Solana </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/avax-avalanche-logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Avalanche </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/hyperledger_logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Hyperledger</p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/cardano-logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Cardano </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/harmony-logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Harmony</p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/tezos-logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Tezos</p>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey='Metaverse Stack'
                  title={<span>Metaverse Stack</span>}>
                  <div className='d-flex justify-content-around flex-wrap gap-4 tab-res'>
                    <div className='img3'>
                      <img
                        src='assets/images/the-sandbox-logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Sandbox</p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/meta-logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Meta</p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/logo.ea444794.png'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Hyperverse</p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/decentraland-logo.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Decentraland</p>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey='Decentrlized Gaming'
                  title={<span>Decentrlized Gaming</span>}>
                  <div className='d-flex justify-content-center flex-wrap gap-4 tab-res'>
                    <div className='img3'>
                      <img
                        src='assets/images/unity.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Unity</p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/unreal-engine.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Unreal</p>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey='Programming Languages'
                  title={<span>Programming Languages</span>}>
                  <div className='d-flex justify-content-around flex-wrap gap-4 tab-res'>
                    <div className='img3'>
                      <img
                        src='assets/images/solidity.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Solidity </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/swift.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Swift </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/kotlin.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Kotlin </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/javascript.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Javascript </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/go.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Go lang </p>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='Framework' title={<span>Framework</span>}>
                <div className='d-flex justify-content-around flex-wrap gap-4 tab-res'>
                    <div className='img3'>
                      <img
                        src='assets/images/node-js.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Node Js  </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/react.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>React Js </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/next-js.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Next Js </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/nestjs.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p> Nest Js </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/web3.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Web3</p>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='Tools' title={<span>Tools</span>}>
                <div className='d-flex justify-content-around flex-wrap gap-4 tab-res'>
                    <div className='img3'>
                      <img
                        src='assets/images/truffle.png'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Truffle  </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/openzeppelin.png'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Openzeppelin  </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/chainlink.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p>Chainlink  </p>
                    </div>
                    <div className='img3'>
                      <img
                        src='assets/images/metamask-icon.svg'
                        className='img-fluid w-50 d-block'
                        alt=''
                      />
                      <br />
                      <p> Metamask </p>
                    </div>
                 
                  </div>
                </Tab>
              </Tabs>

              {/* <p>
                At RELYMER, we take pride in our commitment to security,
                user-friendliness, and innovation. What sets us apart is our
                dedication to delivering the best user experience.
              </p>
              <p>
                Our services are built to be secure, transparent, and efficient,
                ensuring that you can engage with the decentralized world with
                peace of mind.
              </p>
              <p>
                We are continuously pushing the boundaries of what's possible in
                the blockchain space, incorporating Artificial Intelligence for
                smarter decision-making and advanced analytics.
              </p>
              <div className='btn_main'>
                <button className='btn_1'>START BUILDING</button>
                <button className='btn_2'>HOW IT WORKS</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hedera;
