/** @format */

import React from "react";
import { BsLinkedin } from "react-icons/bs";

const Team = () => {
  return (
    <div className="teamWrapper">
      <div className="container">
        <div className=" text-center row mt-5">
          <div className="col-sm-4">
            <div className="teamcol">
              <div className="teamcolinner">
                <div className="avatar">
                  <img src="assets/images/jamil.jpeg" alt="Jamil" />
                </div>
                <div className="member-name">
                  {" "}
                  <h2>Mr. M Jamil</h2>{" "}
                </div>
                <div className="member-info">
                  <p>COO</p>
                </div>
                <div className="member-mail">
                  {" "}
                  <p>
                    {" "}
                    <a href="mailto:jamil@relymer.com">
                      jamil@relymer.com
                    </a>{" "}
                  </p>{" "}
                </div>
                <div className="member-social">
                  <ul className="social-listing">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/muhammadjamil01/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsLinkedin />
                      </a>
                    </li>
                    {/* <li>
                      <a href='#'>
                        <BsTwitter />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="teamcol ">
              <div className="teamcolinner ">
                <div className="avatar">
                  <img src="assets/images/umarCEO.jpg" alt="umar" />
                </div>
                <div className="member-name">
                  {" "}
                  <h2>Mr. M Umar</h2>{" "}
                </div>
                <div className="member-info">
                  <p>CEO & Founder</p>
                </div>
                <div className="member-mail">
                  <p>
                    <a href="mailto:umar@relymer.com">umar@relymer.com</a>{" "}
                  </p>{" "}
                </div>
                <div className="member-social">
                  <ul className="social-listing">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/umarchf/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsLinkedin />
                      </a>
                    </li>
                    {/* <li>
                      <a href='#'>
                        <BsTwitter />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="teamcol">
              <div className="teamcolinner">
                <div className="avatar">
                  <img src="assets/images/mudaser.jpeg" alt="mudaser" />
                </div>
                <div className="member-name">
                  {" "}
                  <h2>Mr. Mudaser Iqbal</h2>{" "}
                </div>
                <div className="member-info">
                  <p>CTO</p>
                </div>
                <div className="member-mail">
                  {" "}
                  <p>
                    {" "}
                    <a href="mailto:mudaseriqbal@gmail.com">
                      mudaseriqbal@gmail.com
                    </a>{" "}
                  </p>{" "}
                </div>
                <div className="member-social">
                  <ul className="social-listing">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/mudaseriqbal/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsLinkedin />
                      </a>
                    </li>
                    {/* <li>
                      <a href='#'>
                        <BsTwitter />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
