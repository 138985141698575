/** @format */

import React from "react";

const data = [
  {
    id: 1,
    image: "assets/images/f1.png",
    btnTxt: "Start building",
    head: "DEVELOPERS",
    para: " Learn how to start building on Relymer, join the developer community, and contribute to the codebase.",
  },
  {
    id: 2,
    image: "assets/images/f2.png",
    btnTxt: "Start using",
    head: "RETAIL USERS",
    para: "Start using permission less dapps built on Relymer, from DeFi protocols to NFT marketplaces and more.",
  },
  {
    id: 3,
    image: "assets/images/f3.png",
    btnTxt: "Start learning",
    head: "Build Career",
    para: "Learn more about Skills Join the community, get a wallet, and view exchanges.",
  },
];

const Started = () => {
  return (
    <>
      <div className='started_sec'>
        <div className='container'>
          <h4>Get Started with Relymer</h4>
          <p>
            Whether you’re a developer, HBAR enthusiast, or web3 <br />
            application user, here’s how to get started with Relymer.
          </p>
          <div className='row mt-5'>
            {data.map(res => {
              return (
                <div className='col-md-6 col-lg-4 mt-4' key={res.id}>
                  <div
                    className='card'
                    style={{ backgroundImage: `url(${res.image})` }}>
                    <h5>{res.head}</h5>
                    <p>{res.para}</p>
                    <div className='btn_main'>
                      <button>{res.btnTxt}</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Started;
