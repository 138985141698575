/** @format */

import React from "react";

const Governance = () => {
    React.useEffect(() => {
        document.title = "Governance | Relymer";
      }, []);
  return (
    <>
      <iframe
        src='https://relymer-gov.netlify.app'
        width='100%'
        title='Governance'
        // scrolling='no'
        frameborder='0'></iframe>
    </>
  );
};

export default Governance;
