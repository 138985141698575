/** @format */

import React from "react";
import Team from "./Team";

const data = [
  {
    id: 1,
    image: "assets/images/d1.svg",
    head: "Augmented/ Virtual reality",
    para: "We Offer Complex Design And Service For Augmented And Virtual Reality. We Help You Through Initial Ideas, 3D Modeling, Postproduction And Motion Design.",
  },
  {
    id: 2,
    image: "assets/images/d2.svg",
    head: "Digital Sovereign identity",
    para: "A Digital Identity Is The Unification Of One’s Identity Across The Web. Multiple Digital Sovereign Identities Present On A Web Are Incorporated Into A Public Immutable Data Base Such As A Blockchain.",
  },
  {
    id: 3,
    image: "assets/images/d3.svg",
    head: "Token development",
    para: "Relymer Uses Blockchain Underlying Technology To Design Customized Tokens For Your Projects And Blockchains. A Token Will Work As A Digital Currency For Your Project, While Being Integrated With Traditional Currency. This Ensures Liquidity Of Your project.",
  },
  {
    id: 4,
    image: "assets/images/d4.svg",
    head: "EVM Tooling & Libraries",
    para: "The EVM on Relymer is optimized for speed and scalability. Deploy smart contracts with ease using your favorite web3 environments, libraries, and tooling.",
  },
];

const Build = () => {
  return (
    <>
      <div className='build_sec'>
        <div className='container'>
          <div className='row mt-5'>
            <div className='col-md-6'>
              <h3>Become Part of the Decentralized Future</h3>
              <p>
                Ready to start on a journey into decentralized excellence? Join
                us today and be part of the revolution. With RELYMER, you'll
                have the tools and support you need to explore, invest, and
                thrive in the exciting world of blockchain and cryptocurrency.
                Don't miss out on this opportunity to be on the cutting edge of
                technology and innovation.
              </p>
              <p>
                Our Team Members Are Blockchain Evangelists; Their Zeal And Zest
                Is Second To No Other. Each New Blockchain Project Is Given Due
                Time And Diligence To Deliver Best Outcome In Coordination With
                Client’s Ideas. Our Teams Work In Rotational Shifts, This
                Results In 24/7 Working Duration. We Also Possess Market
                Experience Of 4+ Years. Along With That We Deliver Timely. Our
                Outstanding Edge Comes From Our Passion For Blockchain
                Technology, We Love What We Do!
              </p>
              <div className='btn_main'>
                <button className='btn_1'>START BUILDING</button>
                <button className='btn_2'>DOCUMENTATION</button>
              </div>
            </div>
            <div className='col-md-6 right'>
              <div className='row d-flex justify-content-center'>
                {data.map((res, id) => {
                  return (
                    <div className='col-md-12 col-lg-5 mt-4' key={id}>
                      <div className=''>
                        <img src={res.image} className='img-fluid' alt='' />
                        <h5>{res.head}</h5>
                        <p>{res.para}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='commitment_sec'>
        <div className='container'>
          <div className='main_card'>
            <div className='row'>
              <div className='col-md-6'>
                <h4>Our Visionary CEO</h4>
              </div>
              <div className='col-md-6'>
                <p>
                  At RELYMER, our journey is led by a visionary CEO who has a
                  deep passion for blockchain technology and its potential.
                  MR.M.Umar brings a wealth of experience and innovation to the
                  table, driving our team to explore new horizons in the world
                  of decentralized technology. With MR.M.Umar at the helm,
                  RELYMER is committed to pushing boundaries and setting new
                  industry standards.
                </p>
              </div>
            </div>
            <Team />
          </div>
        </div>
      </div>
    </>
  );
};

export default Build;
