/** @format */

import React from "react";

const Dex = () => {
  React.useEffect(() => {
    document.title = "Dex | Relymer";
  }, []);
  return (
    <>
      <iframe
        src='https://relymerdex.vercel.app/'
        width='100%'
        title='dex'
        scrolling='no'
        frameborder='0'></iframe>
    </>
  );
};

export default Dex;
