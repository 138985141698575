/** @format */

import React from "react";

const Staking = () => {
  React.useEffect(() => {
    document.title = "Staking | Relymer";
  }, []);

  return (
    <>
      {" "}
      <iframe
        src='https://relymer-stake.netlify.app'
        width='100%'
        title='Staking'
        frameborder='0'></iframe>
    </>
  );
};

export default Staking;
