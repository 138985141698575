/** @format */

import React from "react";
import { BsGithub, BsGlobe } from "react-icons/bs";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const data = [
  {
    id: 1,
    span1: "dao",
    span2: "defi",
    image: "assets/images/currency-exchange.svg",
    head: "DEX",
    para: "DEX offering a full suite of DeFi services.",
    links: "Dex",
  },
  {
    id: 2,
    span1: "defi",
    span2: "Staking",
    image: "assets/images/coins.svg",
    head: "Staking",
    para: "Liquid staking protocol",
    links: "Staking",
  },
  {
    id: 3,
    span1: "Exchange",
    span2: "Bridge",
    image: "assets/images/coin-send.svg",
    head: "Bridge",
    para: "Interoperability of digital assets between networks.",
    links: "Bridge",
  },
  {
    id: 4,
    span1: "nft",
    span2: "marketplace",
    image: "assets/images/nft-sign.svg",
    head: "Marketplace",
    para: "NFT marketplace & launchpad..",
    links: "Marketplace",
  },
  {
    id: 5,
    span1: "rebase",
    span2: "Reward",
    image: "assets/images/freifunk-auto-connect.svg",
    head: "Rebase",
    para: "Auto-Rebasing Layer 1 Blockchain",
    links: "Rebase",
  },
  {
    id: 6,
    span1: "farms",
    image: "assets/images/farming-future.svg",
    head: "Yield Farming",
    para: " A decentralized finance (DeFi) platform to earn a higher return",
    links: "Farms",
  },
  {
    id: 7,
    span1: "dao",
    image: "assets/images/vote.svg",
    head: "Governance",
    para: "A decentralized voting platform",
    links: "Governance",
  },
];

// const data2 = [
//   {
//     id: 1,
//     span1: "DATA INTEGRITY",
//     span2: "PROCUREMENT",
//     image: "assets/images/car7.svg",
//     head: "ServiceNow",
//     para: "Delivering multi-party workflow productivity.",
//   },
//   {
//     id: 2,
//     span1: "IOT",
//     span2: "SUPPLY CHAIN",
//     image: "assets/images/car8.svg",
//     head: "Atma.io by Avery Dennison",
//     para: "Connected product cloud by Avery Dennison.",
//   },
//   {
//     id: 3,
//     span1: "MARKETPLACE",
//     span2: "nfts",
//     image: "assets/images/car9.svg",
//     head: "LG",
//     para: "Sustainable NFT marketplace to buy and sell digital artwork.",
//   },
//   {
//     id: 4,
//     span1: "DATA INTEGRITY",
//     span2: "REGTECH",
//     image: "assets/images/car10.svg",
//     head: "The Coupon Bureau",
//     para: "Maintains universal manufacturer offer promotions.",
//   },
//   {
//     id: 5,
//     span1: "IDENTITY",
//     span2: "PAYMENTS",
//     image: "assets/images/car11.svg",
//     head: "Meeco",
//     para: "Access, control, and create value from personal data.",
//   },
//   {
//     id: 6,
//     span1: "CBDC",
//     span2: "PAYMENTS",
//     image: "assets/images/car12.svg",
//     head: "Emtech",
//     para: "Sustainable CBDC framework for central banks.",
//   },
// ];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Future = () => {
  return (
    <>
      <div className='future_sec' id="Ecosystem">
        <div className='container'>
          <div className='home-hello-future'>
            <div
              className='clip-text'
              style={{
                backgroundImage: 'url("assets/images/f1.png")',
                backgroundPosition: "-28.5312vw 50%",
              }}>
              Relymer
            </div>
            <div className='my-4'>
              <h4>Applications powered by Relymer</h4>
              <p>
                From innovative web3 ecosystems to Fortune 500 companies, <br />
                developers are building the next generation of the web on
                Relymer
              </p>
            </div>
            <hr />

            <div className='cards_sec my-5'>
              <div className='row'>
                <div className='col-md-4'>
                  <h5>
                    WEB3 ECOSYSTEM <br /> APPLICATIONS
                  </h5>
                  <p>
                    Web3 projects are building next-generation applications on
                    Relymer across <br /> DeFi, NFT, creator economy,
                    sustainability, and more.
                  </p>
                  <div className='btn_main2'>
                    <button>View all</button>
                  </div>
                </div>
                <div className='col-md-6 carousel'>
                  <div className='carousel_inner'>
                    <Carousel
                      responsive={responsive}
                      arrows={true}
                      autoPlay={false}
                      autoPlaySpeed={3000}
                      draggable
                      showArrows={true}
                      focusOnSelect={false}
                      infiniteLoop={true}
                      keyBoardControl
                      minimumTouchDrag={80}
                      pauseOnHover
                      renderArrowsWhenDisabled={false}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      showDots={false}>
                      {data.map(res => {
                        return (
                          <div className='card m-auto' key={res.id} >
                            <div className='text_top d-flex justify-content-center flex-wrap align-items-center'>
                              <span>{res.span1}</span>
                              {res.span2 ? <span>{res.span2}</span> : null}
                            </div>
                            <div className='d-flex justify-content-center mt-5'>
                              <img
                                src={res.image}
                                className='img-fluid'
                                alt=''
                              />
                            </div>
                            <hr />
                            <h5>{res.head}</h5>
                            <p>{res.para}</p>
                            <hr />
                            <div className='d-flex justify-content-center align-items-center'>
                              <Link
                                to={res?.links}
                                target='_blank'
                                rel='noopener noreferrer'>
                                <BsGlobe
                                  className='icons'
                                  color='#000'
                                  size={21}
                                />
                              </Link>
                              <BsGithub
                                className='icons'
                                color='#000'
                                size={24}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr />
            <div className='cards_sec my-5'>
              <div className='row'>
                <div className='col-md-4 d-flex flex-column justify-content-center'>
                  <h5>ENTERPRISE APPLICATIONS</h5>
                  <p>
                    Enterprises are using Hedera to improve business processes
                    and procedures across CBDC, Supply Chain, Finance, Fraud
                    Mitigation, and more.
                  </p>
                  <div className='btn_main2'>
                    <button>View all</button>
                  </div>
                </div>
                <div className='col-md-6 carousel'>
                  <div className='carousel_inner'>
                    <Carousel
                      responsive={responsive}
                      arrows={true}
                      autoPlay={false}
                      autoPlaySpeed={3000}
                      draggable
                      showArrows={true}
                      focusOnSelect={false}
                      infiniteLoop={true}
                      keyBoardControl
                      minimumTouchDrag={80}
                      pauseOnHover
                      renderArrowsWhenDisabled={false}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      showDots={false}>
                      {data2.map(res => {
                        return (
                          <div className='card'>
                            <div className='text_top d-flex justify-content-center flex-wrap align-items-center'>
                              <span>{res.span1}</span>
                              {res.span2 ? <span>{res.span2}</span> : null}
                              {res.span3 ? <span>{res.span3}</span> : null}
                            </div>
                            <div className='d-flex justify-content-center mt-5'>
                              <img
                                src={res.image}
                                className='img-fluid'
                                alt=''
                              />
                            </div>
                            <hr />
                            <h5>{res.head}</h5>
                            <p>{res.para}</p>
                            <hr />
                            <div className='btn2_main'>
                              <button>case study</button>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                              <BsGlobe
                                className='icons'
                                color='#000'
                                size={21}
                              />
                              <FaDiscord
                                className='icons'
                                color='#000'
                                size={24}
                              />
                              <BsTwitter
                                className='icons'
                                color='#000'
                                size={24}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Future;
