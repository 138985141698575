/** @format */

import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { AiFillCaretDown } from "react-icons/ai";
const Sections = () => {
  return (
    <>
      <div className='section_bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3 d-flex justify-content-center flex-column align-items-center m-auto'>
              <div className='sec_left'>
                <div>
                  <h3>
                    Revealing Our
                    <span>
                      {" "}
                      Competitive <br /> Uniqueness
                    </span>
                  </h3>
                </div>
                {/* <div>
                  <h3>
                    With <br /> ecosystems <br /> built{" "}
                    <span>
                      by the <br /> community
                    </span>
                  </h3>
                </div> */}
              </div>
            </div>
            <div className='col-md-1 sec_mid p-0 m-0'></div>
            <div className='col-md-8'>
              <div className='sec_right'>
                <h5>
                  <span> From Start To Finish </span>
                  <br />
                  We Take Care Of Everything
                </h5>
                <br />
                <Tabs
                  defaultActiveKey='Research'
                  id='uncontrolled-tab-example'
                  className='mb-3 section-tabs'>
                  <Tab eventKey='Research' title={<span>Research<br />
                        <AiFillCaretDown /></span>}>
                    <p>
                      Our due diligence team consists of industry experts with
                      proficiency in legal considerations, technical expertise,
                      and business facets, including token economics. This
                      expertise empowers us to conduct comprehensive assessments
                      of project feasibility before advancing.
                    </p>
                  </Tab>
                  <Tab
                    eventKey='Design'
                    title={
                      <span>
                        Design <br />
                        <AiFillCaretDown />
                      </span>
                    }>
                    <p>
                      In this phase, projects are either approved or disapproved
                      based on a multitude of factors. If your project
                      successfully clears this stage and receives the green
                      light from our due diligence team, the Business
                      Development (BD) team will then proceed to craft an
                      initial proposal. This proposal will encompass resource
                      allocation and cost estimates, which will subsequently be
                      shared with the project team.
                    </p>
                  </Tab>
                  <Tab eventKey='Build' title={<span>Build<br />
                        <AiFillCaretDown /></span>}>
                    <p>
                      Upon reaching a mutual agreement with the project team, a
                      contract is established, typically entailing a 50% project
                      ownership share and 50% operational cost distribution.
                      Please note that specific terms may vary from one project
                      to another.
                    </p>
                  </Tab>
                  <Tab eventKey='Test' title={<span>Test<br />
                        <AiFillCaretDown /></span>}>
                    <p>
                      The client retains 100% ownership of their project's
                      source code, which will be delivered to them, ensuring
                      full control over their applications.
                    </p>
                  </Tab>
                  <Tab eventKey='Launch' title={<span>Launch<br />
                        <AiFillCaretDown /></span>}>
                    <p>
                      Our proficient marketing team has a track record of
                      assisting clients in securing over $20,000,000 through
                      crowdfunding efforts. We specialize in facilitating
                      fundraising for a diverse range of projects, whether they
                      involve blockchain games, DeFi applications, security
                      protocols, or AI applications.
                    </p>
                  </Tab>
                  <Tab eventKey='Support' title={<span>Support<br />
                        <AiFillCaretDown /></span>}>
                    <p>
                      Our committed support team is available around the clock,
                      24 hours a day, 7 days a week, to address your concerns.
                      We prioritize customer success and the delivery of
                      high-quality products.
                    </p>
                  </Tab>
                </Tabs>

                {/* <button className='btn_1'>Governing council</button> */}
                {/* <div className='images py-5'>
                  <div className='d-flex align-items-center flex-wrap gap-4'>
                    <img
                      src='assets/images/bin.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/ethereum-01.svg'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/trust-1.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/2560px-Uniswap_Logo_and_Wordmark.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/pcs.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/unicript.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/metaverse.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/Polkadot-Logo.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/openAI.png'
                      className='img-fluid'
                      alt=''
                    />

                  
                  </div>
                </div> */}
                {/* <h5>
                  With application ecosystems <br /> and standards built by the{" "}
                  <br /> developer community
                </h5>
                <p>
                  The Relymer codebase and ecosystem standards are open source
                  and contributed by the community through relymer Improvement
                  Proposals (HIPs). The community consists of relymer
                  application and ecosystem developers, node operators, and
                  peripheral organizations.
                </p>
                <button className='btn_1'>View hips</button> */}
                {/* <div className='images py-5'>
                  <div className='d-flex align-items-center flex-wrap gap-4'>
                    <img
                      src='assets/images/Chainlink_Logo_Blue.svg.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/tgbot.png'
                      className='img-fluid '
                      alt=''
                    />
                    <img
                      src='assets/images/s3.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s4.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s5.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s6.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s7.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s8.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s9.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s10.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s11.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s12.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s13.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s14.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s15.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s16.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s17.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s18.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s19.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s20.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s21.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s22.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s23.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s24.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s25.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s26.png'
                      className='img-fluid'
                      alt=''
                    />
                    <img
                      src='assets/images/s27.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sections;
