/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import { BsGithub } from "react-icons/bs";

const Github = () => {
  return (
    <>
      <div className='github_sec'>
        <div className='container'>
          <div className='row card_main'>
            <div className='col-md-4'>
              <h4>Open-source core</h4>
            </div>
            <div className='col-md-8'>
              <div className='btn_main'>
                <a
                  href='https://github.com/RelymerLabs'
                  target='_blank'
                  rel='noopener noreferrer'>
                  {" "}
                  <button>
                    <BsGithub className='mx-2' />
                    GitHub
                  </button>
                </a>
              </div>
              <div className='d-flex justify-content-between'>
                <p>
                  Noone Wallet's core <span>code is openly available</span>{" "}
                  <br /> to anyone who wants to take a closer look
                </p>
                <Link to='#' className='align-self-end'>
                  Github <FiArrowUpRight size={16} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Github;
