/** @format */

import React, { useEffect } from "react";
import { Link as Links } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

const Header = () => {
  useEffect(() => {
    scroll.scrollToTop();
  });

  return (
    <>
      <nav className='navbar fixed-top navbar-expand-lg ' id='header'>
        <div className='container-fluid'>
          <Links className='navbar-brand d-flex align-items-center' to='/'>
            <img src='assets/images/logo.png' width={80} alt='' />

            {/* <span>Relymer</span> */}
          </Links>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div
            className='collapse navbar-collapse '
            id='navbarSupportedContent'>
            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
              <li className='nav-item '>
                <Link className='nav-link buy active' to='Revenue'>
                  Revenue
                </Link>
              </li>
              <li className='nav-item' >
                <Link className='nav-link active' to='Discover'>
                Discover
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link active' to='Ecosystem'>
                  Ecosystem
                </Link>
              </li>
              <li className='nav-item '>
                <a className='nav-link active' target="_blank" rel="noreferrer" href='https://studio.relymer.com/'>
                  Studio
                </a>
              </li>
              <li className='nav-item ' >
                <a className='nav-link active' href='https://news.relymer.com/' target="_blank" rel="noreferrer">
                  News
                </a>
              </li>
          
              <li className=' start_btn'>
                <a className=' ' href='https://labs.relymer.com/' target="_blank" rel="noreferrer">
                  start building
                </a>
              </li>
              {/* <li className='search_bar'>
                <input type='search' placeholder='search' />
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
