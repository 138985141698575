/** @format */

import React from "react";
import Typed from "react-typed";

const Hero = () => {
  return (
    <>
      <div className='hero_sec'>
        <div className='right_img  '>
          <img src='assets/images/hero_right.svg' alt='' />
        </div>
        <div className='container'>
          <a
            href='https://www.linkedin.com/company/relymer-group'
            target='_blank'
            rel='noopener noreferrer'>
            <div className='eyebrow'>Lets Connect</div>
          </a>
          <h3>
          Welcome to <br /> RELYMER
          </h3>
          <div className='repeat_txt'>
            <Typed
              strings={["Your Gateway to", "Decentralized Excellence"]}
              typeSpeed={50}
              backSpeed={50}
              loop></Typed>
          </div>
          <p>
            At RELYMER, we are at the forefront of the decentralized revolution.
            Our cutting-edge software empowers you to navigate the exciting
            world of blockchain and cryptocurrency with confidence. Whether
            you're a seasoned trader or just getting started, RELYMER offers a
            range of services tailored to your needs. Explore, trade, invest,
            and grow with us.
          </p>
          <div className='btn_main'>
            <button className='btn_1'>learn more</button>
            <button className='btn_2'>Explore Documentation</button>
          </div>
          <p className='small_txt'>
            OPEN-SOURCED UNDER A RELYMER BY <br />
            Relymer Labs, Relymer studio, Relymer News
            <br />
          </p>
        </div>
      </div>

      <div className='numbers_sec' id='Revenue'>
        <div className='container'>
          <div className='main_card'>
            <div className='d-flex flex-column card_inner '>
              <div className='head'>
                <h4>110</h4>
              </div>
              <div className='para'>
                <p>
                  Total <br /> Blockchain Projects
                </p>
              </div>
            </div>

            <div className='d-flex flex-column card_inner'>
              <div className='head'>
                <h4>245</h4>
              </div>
              <div className='para'>
                <p>
                  Total <br /> MVPs
                </p>
              </div>
            </div>
            <div className='d-flex flex-column card_inner'>
              <div className='head'>
                <h4>$20,000,000</h4>
              </div>
              <div className='para'>
                <p>
                  Crowd <br /> Funding
                </p>
              </div>
            </div>
            <div className='d-flex flex-column card_inner'>
              <div className='head'>
                <h4>7 Years</h4>
              </div>
              <div className='para'>
                <p>
                  YEARS OF <br /> EXCELLENCY
                </p>
              </div>
            </div>
            <div className='d-flex flex-column card_inner'>
              <div className='head'>
                <h4>30 Days</h4>
              </div>
              <div className='para'>
                <p>
                  Average Project <br /> Duration
                </p>
              </div>
            </div>
            <div className='d-flex flex-column card_inner'>
              <div className='head'>
                <h4>75</h4>
              </div>
              <div className='para'>
                <p>Resources</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
