/** @format */

import React from "react";
import {
  BsEnvelope,
  BsFacebook,
  BsGithub,
  BsInstagram,
  BsLinkedin,
} from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";

const Footer = () => {
  return (
    <>
      <footer
        className='footer text-center text-lg-start text-white'
        style={{ backgroundColor: "#464646" }}>
        <div className='container'>
          <div className='d-flex align-items-center  flex-wrap '>
            <div className='input_main'>
            Subscribe for newsletter
            </div>
            <div className='d-flex align-items-center  footer_right'>
              <div className='btn_main'>
              <a href="https://news.relymer.com" target="_blank" rel="noopener noreferrer"><button>Subscribe</button></a> 
              </div>
              <div className='d-flex justify-content-around  w-100'>
                <a href='mailto:hr@relymer.com'>
                  <BsEnvelope className='icons' color='#8c8c8c' size={24} />
                </a>
                <a
                  href='https://www.linkedin.com/company/relymer-group/mycompany/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <BsLinkedin className='icons' color='#8c8c8c' size={24} />
                </a>
                <a
                  href='https://twitter.com/relymer'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <BsTwitter className='icons' color='#8c8c8c' size={24} />
                </a>
                <a
                  href='https://www.facebook.com/relymer'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <BsFacebook className='icons' color='#8c8c8c' size={24} />
                </a>
                <a
                  href='https://www.instagram.com/relymercom'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <BsInstagram className='icons' color='#8c8c8c' size={24} />
                </a>
                <a
                  href='https://github.com/RelymerLabs'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <BsGithub className='icons' color='#8c8c8c' size={24} />
                </a>
                {/* <a href="http://" target="_blank" rel="noopener noreferrer"><BsYoutube className='icons' color='#8c8c8c' size={24} /></a> */}
                {/* <BsStackOverflow className='icons' color='#8c8c8c' size={24} /> */}
                {/* <BsReddit className='icons' color='#8c8c8c' size={24} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className='d-flex gap-5 justify-content-center align-items-center flex-wrap py-4'>
            <img
              src='assets/images/t1.png'
              style={{ width: "15%" }}
              className='img-fluid'
              alt=''
            />
            <img
              src='assets/images/t2.png'
              style={{ width: "8%" }}
              className='img-fluid'
              alt=''
            />
            <img
              src='assets/images/t3.png'
              style={{ width: "13%" }}
              className='img-fluid'
              alt=''
            />
            <img
              src='assets/images/t4.png'
              style={{ width: "10%" }}
              className='img-fluid'
              alt=''
            />
            <img
              src='assets/images/t5.png'
              style={{ width: "8%" }}
              className='img-fluid'
              alt=''
            />
            <img
              src='assets/images/t6.png'
              style={{ width: "15%" }}
              className='img-fluid'
              alt=''
            />
          </div>
          <div className='d-flex gap-5 justify-content-center flex-wrap py-4'>
            <div>
              <img src='assets/images/f8.png' alt='' />
            </div>
            <div>
              <img src='assets/images/f9.png' alt='' />
            </div>
            <div>
              <img src='assets/images/f10.png' alt='' />
            </div>
            <div>
              <img src='assets/images/f4.png' alt='' />
            </div>
            <div>
              <img src='assets/images/f6.webp' alt='' />
            </div>
            <div>
              <img src='assets/images/f5.png' alt='' />
            </div>
            <div>
              <img src='assets/images/f7.png' alt='' />
            </div>
          </div>

          <div className='d-flex gap-5 justify-content-center align-items-center flex-wrap py-4'>
            <img
              src='assets/images/t7.png'
              style={{ width: "10%" }}
              className='img-fluid'
              alt=''
            />
            <img
              src='assets/images/t8.png'
              style={{ width: "10%" }}
              className='img-fluid'
              alt=''
            />
            <img
              src='assets/images/t9.png'
              style={{ width: "10%" }}
              className='img-fluid'
              alt=''
            />
            <img
              src='assets/images/t10.png'
              style={{ width: "10%" }}
              className='img-fluid'
              alt=''
            />
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
