/** @format */

import React from "react";
import {  FaHeadphonesAlt } from "react-icons/fa";
import { IoIosTimer } from "react-icons/io";
import { TbRefreshDot } from "react-icons/tb";

const data = [
  {
    id: 1,
    icon: <FaHeadphonesAlt size={40} />,
    head: "Dedicated support team",
  },
  {
    id: 2,
    icon: "assets/images/basket.svg",
    head: "Help with App Store and Google play",
  },
  { id: 3, icon: <IoIosTimer size={45} />, head: "99.8% uptime" },
  {
    id: 4,
    icon: <TbRefreshDot size={45} />,
    head: "Regular updates and maintenance",
  },
];

const Work = () => {
  return (
    <>
      <div className='work_sec bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h3>
                <span>Top Notch</span>
                <br /> Market Expertise
              </h3>
            </div>
            <div className='col-md-6'>
              <p>
                Our team's extensive experience in cryptography, DeFi, and
                blockchain technologies, combined with internal audits and
                adherence to industry standards, ensures the quality of your
                future wallet.
              </p>
            </div>
          </div>
          <div className='row'>
            {data.map(res => {
              return (
                <div className='col-md-3 mt-3' key={res.id}>
                  <div className='card'>
                    <div className='card_img'>
                      {res.id === 2 ? <img src={res.icon} alt='' /> : res.icon}
                    </div>

                    <div className='head'>
                      <h4>{res.head}</h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Work;
