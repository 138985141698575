/** @format */

import React from "react";

const Farms = () => {
    React.useEffect(() => {
        document.title = "Farms | Relymer";
      }, []);
  return (
    <>
      <iframe
        src='https://relymer-farms.netlify.app'
        width='100%'
        title='Farms'
        // scrolling='no'
        frameborder='0'></iframe>
    </>
  );
};

export default Farms;
