/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Dex from "./pages/Dex";
import Staking from "./pages/Staking";
import Bridge from "./pages/Bridge";
import Marketplace from "./pages/Marketplace";
import Rebase from "./pages/Rebase";
import Farms from "./pages/Farms";
import Governance from "./pages/Governance";
import ReactGA from 'react-ga';
ReactGA.initialize('G-EDHS1RRM10');
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        {/* <Base> */}
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path='/Dex' element={<Dex />} />
          <Route path='/Staking' element={<Staking />} />
          <Route path='/Bridge' element={<Bridge />} />
          <Route path='/Marketplace' element={<Marketplace />} />
          <Route path='/Rebase' element={<Rebase />} />
          <Route path='/Farms' element={<Farms />} />
          <Route path='/Governance' element={<Governance />} />
          {/* <Route path="/*" element={<PageNotFound />}/> */}
        </Routes>
        {/* </Base> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
