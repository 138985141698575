/** @format */

import React from "react";

const Bridge = () => {
  React.useEffect(() => {
    document.title = "Bridge | Relymer";
  }, []);
  return (
    <>
      {" "}
      <iframe
        src='https://relymer-bridge.netlify.app'
        width='100%'
        title='Bridge'
        // scrolling='no'
        frameborder='0'></iframe>
    </>
  );
};

export default Bridge;
