import React from "react";

const data = [
  {
    id: 1,
    image: "assets/images/c1.svg",
    head: "Decentralized Exchange",
    para: "Trade securely and efficiently on our user-friendly platform.",
  },
  {
    id: 2,
    image: "assets/images/c2.svg",
    head: "NFTs Marketplace",
    para: "Dive into the world of digital art with our vibrant marketplace.",
  },
  {
    id: 3,
    image: "assets/images/c3.svg",
    head: "Blockchain",
    para: "Explore the limitless potential of blockchain technology.",
  },
  {
    id: 4,
    image: "assets/images/c4.svg",
    head: "Smart Contracts",
    para: "Streamline your transactions with smart contract solutions.",
  },
  {
    id: 5,
    image: "assets/images/c5.svg",
    head: "Web Development",
    para: "Create stunning websites with our expert development team.",
  },
  {
    id: 6,
    image: "assets/images/telegrag.svg",
    head: "Telegram Trading Bot",
    para: "Automate your trading strategies with our Telegram Bot.",
  },
];

const Cards = () => {
  return (
    <>
      <div className="cards_sec" id='Discover'>
        <div className="container">
            <div className="d-flex justify-content-between flex-wrap align-items-center">
                <h3>Discover What We Offer</h3>
                <p>RELYMER is your one-stop destination for a wide array of services to cater to the decentralized world and beyond. Our offerings include:</p>
                <button className="btn_1">View all</button>
            </div>
            <hr  />
          <div className="row ">
            {data.map((res) => {
                return(
            <div key={res.id} className="col-md-6 col-lg-4 mt-4">
              <div className="card">
                <img src={res.image} className="img-fluid" alt="" />
                <h4>{res.head}</h4>
                <p>
                  {res.para}
                </p>
                {/* <div className="btn_main">
                  <button>learn more</button>
                </div> */}
              </div>
            </div>
                )
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
