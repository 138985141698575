/** @format */

import React from "react";
import {
  BsEnvelope,
  BsFacebook,
  BsWhatsapp,
} from "react-icons/bs";
import { FaLocationArrow } from "react-icons/fa";


const data = [
  {
    id: 1,
    icon: <BsEnvelope className='icon' size={40} color='#fff' />,
    linkUrl: "mailto:hr@relymer.com",
    link: "hr@relymer.com",
    head: "Email",
  },
  {
    id: 2,
    icon: <BsFacebook className='icon' size={40} color='#fff' />,
    linkUrl: "https://www.facebook.com/relymer",
    link: "@relymer",
    head: "Facebook",
  },
  {
    id: 3,
    icon: <BsWhatsapp className='icon' size={40} color='#fff' />,
    linkUrl: "https://api.whatsapp.com/send/?phone=923337359637",
    link: "+92-333-RELYMER",
    head: "Whatsapp",
  },
  {
    id: 4,
    icon: <FaLocationArrow className='icon' size={35} color='#fff' />,
    head: "First Floor, Ace Uptown, Plot # 22-A, North Road, E 11/2 Islamabad",
  },
];

const Contact = () => {
  return (
    <>
      <div className='contact_sec'>
        <div className='container'>
          <h3>Let's talk</h3>
          <div className='row'>
            {data.map(res => {
              return (
                <div className='col-md-3 col-6 mt-3' key={res.id}>
                  <a
                    className='card'
                    href={res?.linkUrl}
                    target='_blank'
                    rel='noreferrer'>
                    <div className='card_img'>{res.icon}</div>
                    <div>
                      <div className='link'>
                        <a href={res?.linkUrl} target='_blank' rel='noreferrer'>
                          {res.link}
                        </a>
                      </div>
                      <div className='head'>
                        <h4>{res.head}</h4>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
