/** @format */

import React from "react";
import Hero from "../components/Elements/Hero";
import Sections from "../components/Elements/Sections";
import Hedera from "../components/Elements/Hedera";
import Cards from "../components/Elements/Cards";
import Started from "../components/Elements/Started";
import Build from "../components/Elements/Build";
import Future from "../components/Elements/Future";
import Services from "../components/Elements/Services";
import Contact from "../components/Elements/Contact";
import Work from "../components/Elements/Work";
import Github from "../components/Elements/Github";
import Header from "../components/Layouts/Header";
import Footer from "../components/Layouts/Footer";

const Home = () => {
  React.useEffect(() => {
    document.title = "Relymer";
  }, []);
  return (
    <>
      <Header />
      <Hero />
      <Sections />
      <Hedera />
      <Future />
      <Github />
      <Cards />
      <Services />
      <Started />
      <Build />
      <Work />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
