/** @format */

import React from "react";

const Services = () => {
  return (
    <>
      <div className='services_sec'>
        <div className='container'>
          <div className='card_main'>
            <h2>- Perks at Work in Relymer -</h2>
            <div className='row mt-4'>
              <div className='col-md-6 col-lg-4 col-6 my-4'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/a1.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <p className='text-center'>
                    In-patient & Out-patient <br /> Medical Coverage
                  </p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 col-6 my-4'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/a2.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <p className='text-center'>Leaves/Vacation</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 col-6 my-4'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/a3.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <p className='text-center'>
                    Transport/Internet <br /> Allowance
                  </p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 col-6 my-4'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/a4.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <p className='text-center'>Provident Fund</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 col-6 my-4'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/a5.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <p className='text-center'>Free Snacks</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 col-6 my-4'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/a6.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <p className='text-center'>Profit Sharing</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 col-6 my-4'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/a7.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <p className='text-center'>
                    Health & Fitness <br /> Benefit
                  </p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 col-6 my-4'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/a8.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <p className='text-center'>Fun Events/Trip</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 col-6 my-4'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <img
                      src='assets/images/a9.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                  <p className='text-center'>Gadget Discount</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
